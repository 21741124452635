.panelSegmentBody .panelSegmentRow:hover {
	background-color: rgba(0, 0, 0, 0.04);
}

.panelSegmentBody .panelSegmentRow .iconButton {
	opacity: 0;
}

.panelSegmentBody .panelSegmentRow:hover .iconButton {
	opacity: 1;
}
